import * as echarts from 'echarts';
import './chain.js' // 引入中国地图数据
const install = function (Vue) {
    Object.defineProperties(Vue.prototype, {
        $chart: {
            get() {
                return {
                    // 通用图表
                    commonChartFunc: function (id, option) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        this.chart.setOption(option)
                    },
                    //折线图
                    chartLine: function (id, echartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            title: {
                                text: name
                            },
                            backgroundColor: "#FFF",
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'cross',
                                    label: {
                                        backgroundColor: '#6a7985'
                                    }
                                }
                            },
                            legend: {
                                data: echartData.title
                            },
                            toolbox: {
                                feature: {
                                    saveAsImage: {}
                                }
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: [{
                                type: 'category',
                                boundaryGap: false,
                                data: echartData.line
                            }],
                            yAxis: [{
                                type: 'value'
                            }],
                            series: echartData.data
                        };
                        this.chart.setOption(option);
                    },
                    //柱状图
                    chartColumn: function (id, echartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            color: '#409eff',
                            backgroundColor: "#FFF",
                            title: {
                                text: name,
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },

                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: echartData.title
                            },
                            yAxis: {
                                type: 'value',
                                boundaryGap: [0, 0.01]
                            },
                            series: [{
                                name: '人数',
                                type: 'bar',
                                data: echartData.data,
                                barWidth: echartData.data.length > 5 ? 'auto' : 150 // 根据数据数量动态设置柱状图宽度
                            }]
                        };
                        this.chart.setOption(option);
                    },
                    // 奖品柱状图
                    priseColumnChart: function (id, echartData,bottomData) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        let colorData = ['#409EFF', '#67C23A', '#F56C6C', '#FAC858'];


                        let titleData = [];//顶部联动
                        let rawData = []; //图形数据
                        
                        echartData.forEach((value, index) => {
                            rawData.push(value.data)
                            titleData.push(value.name)
                        })

                        const totalData = [];
                        for (let i = 0; i < rawData[0].length; ++i) {
                            let sum = 0;
                            for (let j = 0; j < rawData.length; ++j) {
                                sum += rawData[j][i];
                            }
                            totalData.push(sum);
                        }
                        let chartData = titleData.map((name, ind) => {
                            return {
                                name,
                                type: 'bar',
                                stack: 'total',
                                barWidth: '25%',
                                emphasis: {
                                    disabled: true,
                                    focus: 'none'
                                },
                                label: {
                                    show: true,
                                    formatter: function(params) {
                                        let mathNumber = Math.round(params.value * 10000) / 100
                                        if(mathNumber > 0) {
                                            return mathNumber + '%'
                                        }else{
                                            return ''
                                        }
                                    }
                                },
                                data: rawData[ind].map((d, did) =>
                                    totalData[did] <= 0 ? 0 : d / totalData[did]
                                ),
                                itemStyle: {
                                  color: colorData[ind],
                                }
                            };
                        });
                        // 修改坐标轴样式
                        let xStyle = {
                            axisLabel: {
                                show: true,
                                rotate: 0,
                                margin: 10,
                                interval: 0,
                                lineHeight: 50,
                            },
                        }
                        const option = {
                            tooltip: {
                                trigger: 'axis',
                                // axisPointer: { ...},
                                formatter: function (params) {
                                    let res = `<div style="text-align:center">${params[0].name}</div>`;
                                    echartData.forEach((item,index) => {
                                        // 获取当前数据列表
                                        res += `<div><span>${item.name}：</span>${item.data[params[0].dataIndex]}</div>` 
                                    })
                                    return res
                                }
                            },
                            legend: {},
                            grid: {
                                left: '1%',
                                right: '1%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    ...xStyle,
                                    type: 'category',
                                    data: bottomData
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value'
                                }
                            ],
                            series: chartData
                            // 对x轴字数过多时的处理展示
                            // axisLabel: {
                            //     interval: 0,
                            //     formatter: function (value) {
                            //         var ret = "";//拼接加\n返回的类目项  
                            //         var maxLength = 5;//每项显示文字个数  
                            //         var valLength = value.length;//X轴类目项的文字个数  
                            //         var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数  
                            //         if (rowN > 1)//如果类目项的文字大于5,  
                            //         {
                            //             for (var i = 0; i < rowN; i++) {
                            //                 var temp = "";//每次截取的字符串  
                            //                 var start = i * maxLength;//开始截取的位置  
                            //                 var end = start + maxLength;//结束截取的位置  
                            //                 //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧  
                            //                 temp = value.substring(start, end) + "\n";
                            //                 ret += temp; //凭借最终的字符串  
                            //             }
                            //             return ret;
                            //         }
                            //         else {
                            //             return value;
                            //         }
                            //     }
                            // }
                        };
                        this.chart.setOption(option);
                    },
                    // 堆叠柱状图 
                    stackedColumnChart: function (id, titleData, chartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();

                        // 修改坐标轴样式
                        let xStyle = {
                            axisLabel: {                 	// 坐标轴 标签
                                show: true,                  // 是否显示
                                rotate: 0,                   // 旋转角度
                                margin: 10,                 // 刻度标签与轴线之间的距离
                                // fontSize: 30 ,			//设置坐标轴文本标签的字体大小
                                interval: 0,
                                lineHeight: 20,
                                // color:'#6B6B6B',         // 默认取轴线的颜色
                            },
                            // axisLine:{                  	// 坐标轴 轴线
                            // 	show:true,                  // 是否显示
                            // }
                        }
                        const option = {
                            title: {
                                text: name
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {},
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    ...xStyle,
                                    type: 'category',
                                    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                                    data: titleData,
                                    axisLabel: {
                                        interval: 0, // 显示所有标签
                                        formatter: function (value, index) {
                                            var result = '';
                                            var count = 0;
                                            for (var i = 0; i < value.length; i++) {
                                                result += value[i];
                                                count++;
                                                if (count % 6 === 0) {
                                                    result += `\n`;
                                                }
                                            }
                                            return result;
                                        }
                                    },
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value'
                                }
                            ],
                            // series: [
                            //     {
                            //         name: 'Baidu',
                            //         type: 'bar',
                            //         stack: 'Search Engine',
                            //         emphasis: {
                            //             focus: 'series'
                            //         },
                            //         data: [620, 732, 701, 734, 1090, 1130, 1120]
                            //     }
                            // ]
                            series: chartData
                        };
                        this.chart.setOption(option);
                    },
                    // 性别比例-饼图
                    chartPie: function (id, echartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            title: {
                                text: name
                            },
                            textStyle: {
                                align: 'center'
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c} ({d}%)'
                            },
                            legend: {
                                orient: 'horizontal',
                                align: 'auto',
                                // bottom: '0',
                                data: echartData.title
                            },
                            toolbox: {
                                feature: {
                                    saveAsImage: {}
                                }
                            },
                            series: [{
                                name: '访问来源',
                                type: 'pie',
                                radius: ['50%', '70%'],
                                avoidLabelOverlap: false,
                                label: {
                                    show: false,
                                    position: ''
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: '20',
                                        fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: echartData.data
                            }],
                            color: ['#409eff', '#ff486b', '#888']
                        };
                        this.chart.setOption(option)
                    },
                    // 环形图
                    doughnutChart: function (id, echartData, name, total, radius = ['60%', '100%']) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        let isShowLabel = false;  //是否展示提示文字，当总数为0时不展示
                        if (total) {
                            isShowLabel = true;
                        } else {
                            isShowLabel = false;
                        }
                        this.chart.setOption({
                            title: {
                                text: name
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: '4%',
                                top: '30%'
                            },
                            series: [
                                {
                                    name: name,
                                    type: 'pie',
                                    left: '25%',
                                    top: '40%',
                                    hoverAnimation: false,
                                    startAngle: -180,
                                    radius: ["120%", "200%"],
                                    center: ["65%", "70%"],
                                    // radius: ['45%', '70%'],
                                    // center: ['50%', '70%'],
                                    // startAngle: 180,
                                    // endAngle: 360,
                                    avoidLabelOverlap: false,
                                    label: {
                                        show: isShowLabel,
                                        formatter(param) {
                                            if(isShowLabel) {
                                                return param.name + ' (' + param.percent *2 + '%)';
                                            } else {
                                                return '0%'
                                            }
                                        },
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        }
                                    },
                                    labelLine: {
                                        show: false
                                    },
                                    data: echartData,
                                    itemStyle: {
                                        normal: {
                                            color: function (colors) {
                                                var colorList = [
                                                    '#409EFF',
                                                    '#5470c6',
                                                    '#67C23A',
                                                    '#E6A23C',
                                                    '#F56C6C',
                                                    '#fac858',
                                                    "rgba(0,0,0,0)"
                                                ];
                                                return colorList[colors.dataIndex];
                                            }
                                        },
                                        emphasis: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: "rgba(0, 0, 0, 0.5)",
                                        },
                                    }
                                }
                            ]
                        })
                    },
                    // 扫码数据
                    scanDataChart: function (id, echartData, name, total, radius = ['50%', '80%']) {
                        let angel;
                        // 判断当数据为0的时候，设置角度从180开始，为了适配样式不错乱
                        if (total) {
                            angel = 90;
                        } else {
                            angel = 180
                        }
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        this.chart.setOption({
                            title: {
                                text: name
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                orient: 'vertical',
                                left: '5%',
                                top: '36%'
                            },
                            series: [
                                {
                                    name: name,
                                    type: 'pie',
                                    left: '20%',
                                    bottom: '7%',
                                    startAngle: angel,
                                    radius: radius,
                                    avoidLabelOverlap: false,
                                    label: {
                                        show: true,
                                        formatter(param) {
                                            return param.name + ' (' + param.percent + '%)';
                                        },
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        }
                                    },
                                    labelLine: {
                                        show: false
                                    },
                                    data: echartData,
                                    itemStyle: {
                                        normal: {
                                            color: function (colors) {
                                                var colorList = [
                                                    '#F56C6C',
                                                    '#409EFF'
                                                ];
                                                return colorList[colors.dataIndex];
                                            }
                                        },
                                    }
                                }
                            ]
                        })
                    },
                    // 基础南丁格尔玫瑰图
                    roseChart(id,echartData,radius = [20, 100]){
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        
                        const option = {
                            legend: {
                            top: 'top'
                            },
                            toolbox: {
                            show: true,
                            },
                            series: [
                                {
                                    name: 'Nightingale Chart',
                                    type: 'pie',
                                    radius: radius,
                                    center: ['50%', '50%'],
                                    roseType: 'area',
                                    itemStyle: {
                                    borderRadius: 8
                                    },
                                    data: echartData
                                }
                            ]
                        };
                        this.chart.setOption(option)
                    },
// 首页用户数据概览
                    shouPopulationChart: function (id, titleData, echartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            color: '#409EFF',
                            title: {
                                text: name
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {},
                            grid: {
                                left: '3%',
                                right: '4%',
                                top: '-20%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                boundaryGap: [0, 1]
                            },
                            yAxis: {
                                type: 'category',
                                data: titleData
                            },
                            // series: [
                            //     {
                            //         name: '2011',
                            //         type: 'bar',
                            //         data: [18203, 23489, 29034, 104970, 131744, 630230]
                            //     }
                            // ]
                            series: echartData
                        };
                        this.chart.setOption(option)
                    },
                    // 扫码次数
                    populationChart: function (id, titleData, echartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            title: {
                                text: name
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {},
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                boundaryGap: [0, 1]
                            },
                            yAxis: {
                                type: 'category',
                                data: titleData
                            },
                            // series: [
                            //     {
                            //         name: '2011',
                            //         type: 'bar',
                            //         data: [18203, 23489, 29034, 104970, 131744, 630230]
                            //     }
                            // ]
                            series: echartData
                        };
                        this.chart.setOption(option)
                    },
                    // 导购商品码概况-饼图
                    daogouProductCodeOverviewPie: function (id, echartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            title: {
                                text: name
                            },
                            textStyle: {
                                align: 'center',
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c} ({d}%)'
                            },
                            legend: {
                                orient: 'vertical',
                                data: echartData.title,
                                right: '4%',
                                top: '30%'
                            },
                            label: {
                                show: true,
                                formatter: '{b}: {c}'
                            },
                            series: [{
                                name: '导购商品码概况',
                                type: 'pie',
                                radius: '70%',
                                avoidLabelOverlap: false,
                                emphasis: {
                                },
                                data: echartData.data
                            }],
                            color: ['#5470C6', '#91CC75', '#FAC858']
                        };
                        this.chart.setOption(option)
                    },
                    // 导购概况
                    daogouOverviewStackedColumn: function (id, titleData, chartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();

                        // 修改坐标轴样式
                        let xStyle = {
                            axisLabel: {                 	// 坐标轴 标签
                                show: true,                  // 是否显示
                                rotate: 0,                   // 旋转角度
                                margin: 10,                 // 刻度标签与轴线之间的距离
                                // fontSize: 30 ,			//设置坐标轴文本标签的字体大小
                                interval: 0,
                                lineHeight: 20,
                                // color:'#6B6B6B',         // 默认取轴线的颜色
                            },
                        }
                        const option = {
                            title: {
                                text: name
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {},
                            grid: {
                                left: '1%',
                                right: '1%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    ...xStyle,
                                    type: 'category',
                                    data: titleData
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value'
                                }
                            ],
                            series: chartData
                        };
                        this.chart.setOption(option);
                    },
                    // 导购商品
                    daogouProductpopulationChart: function (id, titleData, echartData, name) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            title: {
                                text: name
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {},
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                boundaryGap: [0, 1]
                            },
                            yAxis: {
                                type: 'category',
                                data: titleData
                            },
                            series: echartData
                        };
                        this.chart.setOption(option)
                    },
                    //扫码时段
                    scanTime: function(id, echartData,name ='时段统计图') {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            title: {
                                text: name
                            },
                            backgroundColor: "#FFF",
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'cross',
                                    label: {
                                        backgroundColor: '#6a7985'
                                    }
                                }
                            },
                            legend: {
                                data: echartData.title
                            },
                            // toolbox: {
                            //     feature: {
                            //         saveAsImage: {}
                            //     }
                            // },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: [{
                                type: 'category',
                                boundaryGap: false,
                                data: echartData.line
                            }],
                            yAxis: [{
                                type: 'value'
                            }],
                            series: echartData.data
                        };
                        this.chart.setOption(option);
                    },
                    //扫码时段 对比
                    scanTimeRatio: function (id, echartData) {
                        const colors = ['#67C23A', '#E6A23C','#409EFF','#F56C6C'];
                        const option = {
                            color: colors,
                            title: {
                                text: '时段统计图'
                            },
                            backgroundColor: "#FFF",
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'cross',
                                }
                            },
                            // legend: {
                            //     data: echartData.title
                            // },
                            // toolbox: {
                            //     feature: {
                            //         saveAsImage: {}
                            //     }
                            // },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    boundaryGap: false,
                                    axisLine: {
                                        onZero: false,
                                        lineStyle: {
                                            color: colors[1]
                                        }
                                    },
                                    onZero:false,
                                    data: echartData.line1,
                                },
                            ],
                            yAxis: [{
                                type: 'value'
                            }],
                            series: echartData.data
                        };
                        var chartDom = document.getElementById(id);
                        var chartInstance = echarts.getInstanceByDom(chartDom);
                        if (chartInstance) {// 如果已经存在初始化 直接渲染
                            chartInstance.setOption(option)
                        } else {
                            this.chart = echarts.init(document.getElementById(id));
                            this.chart.clear();
                            this.chart.setOption(option);
                        }
                    },
                    //扫码地域 -地图
                    scanArea: function (id, echartData) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            title: {
                                subtext: echartData.name,
                                left: 'center'
                            },
                            backgroundColor: "#FFF",
                            tooltip: {
                                trigger: 'item',
                                // formatter: '{b}<br/>扫码数 {c} '
                                formatter: function (params) {
                                    if (params.value) {
                                        return params.name + '<br/>' + '扫码数' + ' : ' + params.value;
                                    } else {
                                        return params.name + '<br/>' + '扫码数' + ' : ' + '0';
                                    }
                                }
                            },
                            toolbox: {
                                show: true,
                                orient: 'vertical',
                                left: 'right',
                                top: 'center',
                            },
                            geo: {
                                map: 'china', // 表示中国地图
                                roam: true,
                                label: {
                                    show: true, // 是否显示对应地名
                                    color: 'rgba(0,0,0,0.4)'
                                },
                                itemStyle: {
                                    borderColor: 'rgba(0, 0, 0, 0.2)'
                                }
                            },
                            visualMap: {
                                min: 1,
                                max: 1000,
                                text: ['高', '低'],
                                realtime: false,
                                calculable: true,
                                inRange: {
                                    color: ['lightskyblue', 'yellow', 'orangered']
                                }
                            },
                            series: [
                                {
                                    geoIndex: 0,
                                    emphasis: {
                                        areaColor: '#97D4D9' // 高亮显示的颜色
                                    },
                                    data: echartData.top,
                                    type: 'map',
                                }
                            ]
                        };
                        this.chart.setOption(option);
                    },
                    //扫码地域 -前十
                    scanTop: function (id, echartData) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const option = {
                            title: {
                                subtext: '扫码地域前10区域统计',
                                // subtext: '虚构数据',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter: "{a} <br/>{b} : {c} ({d}%)"
                            },
                            legend: {
                                type: 'scroll',
                                orient: 'vertical',
                                bottom: 10,
                                left: 'right',
                                data: echartData.legend
                            },
                            series: [{
                                type: 'pie',
                                radius: '60%',
                                center: ['50%', '50%'],
                                name: '扫码次数',
                                selectedMode: '数量',
                                data: echartData.top,
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }]
                        };
                        this.chart.setOption(option);
                    }
                }
            }
        }
    })
}

export default {
    install
}
